

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header bg-white">
            <b-form-group label="Filter by Role" class="mb-2" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                  id="radio-group-2"
                  v-model="defaultRole"
                  :aria-describedby="ariaDescribedby"
                  name="radio-default-status"
              >
                  <b-form-radio  value="all">All</b-form-radio>
                  <b-form-radio  value="admin">Admin</b-form-radio>
                  <b-form-radio  value="worker">Worker</b-form-radio>
                  <b-form-radio  value="buyer">Buyer</b-form-radio>
                  <b-form-radio  value="staff">Staff</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>
          <div class="card-body">
            <div class="row mb-2">
              <div class="col-sm-12 col-md-8">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex mr-2 align-items-center">
                    <b-form-select v-model="itemsPerPage" :options="pageOptions"></b-form-select>
                  </label>
                    <b-dropdown left variant="light">
                      <template slot="button-content">
                        <i class="mdi mdi-filter-variant"></i>
                      </template>
                      <b-dropdown-item-button @click="selectAllRows()"><i :class="isSelectAll? 'mdi mdi-playlist-remove' : 'mdi mdi-playlist-check'" ></i> {{isSelectAll? 'DeSelect All' : 'Select All' }}</b-dropdown-item-button>
                      <b-dropdown-item-button ><i class="mdi mdi-delete-forever-outline"></i> Delete</b-dropdown-item-button>
                    </b-dropdown>
                </div>
              </div>
              <div class="col-sm-12 col-md-4">
                <div class="">
                  <label class="d-block">
                    <b-form-input v-model="searchQuery" @input="updateSearchQuery" type="search" placeholder="Search..." class="form-control"></b-form-input>
                  </label>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-0 loading-viewport">
              <is-loading v-if="isLoading" :box="true" />
              <b-table striped hover table-class="table font-sm table-centered w-100" thead-tr-class=""
                  ref="selectableTable" :items="users" :fields="columns" responsive="sm"  >
                    <template v-slot:cell(check)="data">
                      <div class="custom-control custom-checkbox text-center">
                        <input v-model="selected" type="checkbox" :value="data.item.id" class="custom-control-input" :id="`checkuser${data.item.id}`" />
                        <label class="custom-control-label" :for="`contacusercheck${data.item.id}`">{{data.item.id}}</label>
                      </div>
                    </template>
  
                    <template v-slot:cell(image)="data">
                      <img @click="navigateTo(data.item.id)" :src="absoluteUrl(data.item.image)" alt="" class="rounded" style="cursor: pointer;" height="48" />
                    </template>
                    <template v-slot:cell(name)="data">
                      <h5 class="m-0 d-inline-block align-middle">
                        <a href="#" @click.prevent="navigateTo(data.item.id)" v-if="data.item.role == 'worker'" class="text-dark">{{ data.item.username }}</a>
                        <a href="#" @click.prevent="navigateTo(data.item.id)" v-else class="text-dark">{{ data.item.first_name }} {{ data.item.last_name }}</a>
                      </h5>
                    </template>
                    <template v-slot:cell(email)="data">
                      <h5 class="m-0 d-inline-block align-middle text-truncate" style="width: 150px;">
                        <a href="#" @click.prevent="navigateTo(data.item.id)" class="text-dark">{{ data.item.email }}</a>
                      </h5>
                    </template>
                    <template v-slot:cell(country)="data">
                      <h5 class="m-0 d-inline-block align-middle text-truncate" style="width: 100px;">
                        <a href="#" @click.prevent="navigateTo(data.item.id)" class="text-dark">{{ data.item.country ? data.item.country.name : ''  }}</a>
                      </h5>
                    </template>
                    <template v-slot:cell(status)="data">
                      <span class="badge badge-soft-success" :class="{'badge-soft-danger': data.item.status === 'deactivated'}">{{ data.item.status }}</span>
                    </template>
                    <template v-slot:cell(is_verified)="data">
                      <span class="d-block text-center">
                        <span class="badge badge-soft-success" :class="{'badge-soft-danger': data.item.is_verified}">{{ data.item.is_verified? '✔️' : '❌' }}</span>
                      </span>
                    </template>
                    <template v-slot:cell(action)="data">
                        <ul class="list-inline table-action m-0">
                          <li v-if="data.item.id != activeUser.id" class="list-inline-item">
                            <a @click="deleteItem(data.item.id)" href="javascript:void(0);" class="action-icon text-danger">
                                <i class="mdi mdi-delete"></i></a>
                          </li>
                        </ul>
                    </template>
                </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="paging_simple_numbers float-right">
                  <b-pagination v-model="currentPage" class="pagination-rounded mb-0"
                    :total-rows="pageData.total" :limit="3" :per-page="itemsPerPage"></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  </template>
  
  <script>
  import IsLoading from '@/components/IsLoading.vue';
  import _ from 'lodash';
  
  export default {
    components:{
      IsLoading
    },
    data() {
      return {
        isLoading: true,
        defaultRole: "all",
        searchQuery:"",
        pageOptions: [10, 25, 50, 100],
        columns: [
            {
                key: "check",
                label: "ID"
            },
            {
                key: "image",
                sortable: true
            },
            {
                key: "name",
                sortable: true
            },
            {
                key: "email",
                sortable: true
            },
            {
                key: "country",
                sortable: true
            },
            {
                key: "status",
                label: "Status",
                sortable: true
            },
            {
                key: "is_verified",
                label: "Verified",
                sortable: true
            },
            "action"
        ],
        selected: [],
        isSelectAll: false,
      }
    },
    watch: {
      "$route.query":"refreshQueryRole",
      defaultRole (val) {
        this.fetchUsers()
      }
    },
    computed: {
      activeUser(){
        return this.$store.state.AppActiveUser
      },
      pageData(){
        return this.$store.state.userList.pageData
      },
      users() {
        return this.pageData.data.filter((user) => this.defaultRole == "all" 
              || (user.role == this.defaultRole))
      },
      currentPage: {
        get() {
          return this.pageData.current_page
        },
        set(val) {
          if(!this.pageData.current_page || this.pageData.current_page == val) return
          this.fetchUsers(val)
        }
      },
      itemsPerPage: {
        get() {
          return +this.pageData.per_page || 10
        },
        set(val) {
          this.fetchUsers(1, val)
        }
      },
    },
    methods: {
      refreshQueryRole(){
          if(this.$route.query.role){
            this.defaultRole = this.$route.query.role
          }
      },
      navigateTo(id){
        this.$router.push({path: id == this.activeUser.id
          ? `/account` : `/users/${id}`});
      },
      selectAllRows() {
        this.isSelectAll = !this.isSelectAll
        if(this.isSelectAll){
          this.selected = this.usersData.map(u => u.id );
        }else{
          this.selected = []
        }
      },
      updateSearchQuery: _.debounce(function(string) {
        this.fetchUsers()
      }, 2000),
      fetchUsers(page, per_page = null){
        let payload = {page: page || 1, per_page: per_page || this.itemsPerPage }
        if(this.defaultRole != 'all'){ payload.role = this.defaultRole }
        if(this.searchQuery){ payload.search = this.searchQuery }
        this.isLoading = true
        this.$store.dispatch("userList/fetchUsers", payload)
        .then(response => this.isLoading = false)
      },
      deleteItem(itemId){
        this.confirmDelete({
          text: "You want to delete a user",
        }).then((result) => {
          if (result.value) {
            this.$store.dispatch("changeLoaderValue",true)
            this.$http.delete(`/users/${itemId}/delete`)
            .then(response => {
              this.$store.dispatch("changeLoaderValue",false)
              if(response.data.success){
                this.$store.commit('userList/DELETE_USER', itemId)
              }
            })
          }
        });
      },
    },
    created() {
      this.refreshQueryRole()
      if(this.defaultRole == "all"){this.fetchUsers()}
    }
  }
  
  </script>
  
  <style lang="scss">
  
  </style>
  